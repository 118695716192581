/* eslint-disable max-len */
export default {
  application: {
    name: 'CCD Ensino - Dashboard',
    shortDescription: 'CCD Ensino',
    title: 'Home',
    version: {
      title: 'Versão',
      number: '1.0.0',
    },
    footer: {
      poweredBy: 'Powered by',
      poweredByBrand: 'CCD Ensino',
      poweredByBrandUrl: 'http://ccdensino.com.br',
    },
  },
  shared: {
    affirmative: {
      yes: 'Sim',
      no: 'Não'
    },
    notFoundSearch: 'Nada encontrado.',
    selectSomeValue: 'Selecione...',
    typeToContinue: 'Digite algo para pesquisar...',
    type: 'Digite...',
    yes: 'Sim',
    no: 'Não',
    hi: 'Olá',
    save: 'Salvar',
    remove: 'Remover',
    loading: 'Carregando...',
    confirmTitle: 'Você tem certeza?',
    exportCsvButtonText: 'Exportar CSV',
    exportAllCsvButtonText: 'Exportar tudo em CSV',
    exportPdfButton: 'Exportar PDF',
    exportAllPdfButton: 'Exportar tudo em PDF',
    next: 'Próximo',
    back: 'Voltar',
    awaitData: 'Os dados ainda não foram carregados ou estão vazios',
    logout: {
      menuText: 'Sair do sistema',
      confirmation: 'Você tem certeza que deseja sair?',
      confirmButton: 'Tenho certeza, sair.',
    },
    nothingToShow:
      'Nada para exibir, verifique os parâmetros e tente novamente.',
    hours: 'horas',
    minutes: 'minutos',
    seconds: 'segundos',
    disclaimerModal: {
      okText: 'Entendi',
      cancelText: 'Não desejo prosseguir, sair',
    },
    advancedFilters: {
      title: 'Filtros avançados de pesquisa',
      filterButtonText: 'Filtrar',
      clearButtonText: 'Limpar filtros',
      form: {
        questionNumber: 'Número da questão',
        city: 'Cidades com dados cadastrados',
        cityLabel: 'Cidade',
        company: 'Empresa',
        contentName: 'Nome do conteúdo',
        userCourse: 'Cursos do usuário',
        course: 'Curso',
        module: 'Modulo',
        content: 'Conteúdo',
        created: 'Data de criação',
        email: 'E-mail',
        endDate: 'Final',
        hospital: 'Hospital',
        isEnabled: 'Disponível',
        mainCategory: 'Categoria principal',
        category: 'Categoria',
        name: 'Nome',
        nameUser: 'Nome do usuário',
        parentId: 'Curso relacionado',
        period: 'Período',
        postDate: 'Data de exibição',
        specialization: 'Especialização',
        startDate: 'Início',
        state: 'Estados com dados cadastrados',
        supportsDaily: 'Suporta daily',
        title: 'Título',
        type: 'Tipo',
        local: 'Local',
        difficulty: 'Dificuldade',
        questionDescription: 'Enunciado',
        content: 'Conteúdos',
        quiz: 'Simulados/Provas',
        planStatus: 'Situação do Plano',
        isUserActive: 'Atividade do usuário no curso',
        userPlan: 'Plano do usuário',
        responseStatus: 'Status',
        percentage: 'Porcentagem de acerto',
        order: 'Ordenar por',
        recent: 'Mais recentes',
        older: 'Mais antigos',
        createdAt: 'Data de criação',
        year: 'Ano',
        id: 'ID',
        profileType: 'Tipo de conta',
        telephone: 'Telefone',
        plan: 'Plano',
        titleContent: 'Título do conteúdo',
      },
    },
    uploadFile: {
      addFiles: 'ADICIONAR ARQUIVO',
      addPhoto: 'Upload foto',
    },
    caractersCount: {
      text: 'Caracteres: '
    }
  },
  routes: {
    login: {
      url: '/',
      pageTitle: 'Entrar',
      content: {
        email: 'E-mail',
        emailPlaceholder: 'Seu e-mail cadastrado',
        password: 'Senha',
        passwordPlaceholder: 'Sua senha segura',
        continueButton: 'ENTRAR NO SISTEMA',
        welcome: 'Bem vindo(a) novamente.',
        forgotPassword: 'esqueci minha senha, ',
        forgotPasswordAction: 'Clique aqui.',
      },
      errors: {
        fields: 'Verifique os campos e tente novamente',
        invalid_credentials:
          'Credenciais inválidas, verifique e tente novamente.',
        user_invalid_token:
          'Sua sessão expirou. Por favor, faça o login novamente.',
        user_not_found: 'Credenciais inválidas, verifique e tente novamente.',
        address_number: 'Adicione o número do endereço',
        address_error: 'Adicione um endereço valido',
        same_date: 'Adicione datas diferentes',
      },
      messages: {
        welcome: 'Bem vindo(a) novamente.',
      },
    },
    passwordRecovery: {
      url: '/recuperacao-senha',
      urlApp: '/api/deeplink/password-recovery',
      pageTitle: 'Recuperação de senha',
      content: {
        step1:
          'Por favor, insira seu e-mail cadastrado. Iremos lhe enviar um link para atualização da senha.',
        step2: 'Digite sua nova senha',
        email: 'E-mail',
        emailPlaceholder: 'Seu e-mail cadastrado',
        password: 'Senha',
        passwordPlaceholder: 'Sua senha segura',
        confirmPassword: 'Confirmar senha',
        confirmPasswordPlaceholder: 'Confirmação de senha',
        recoveryToken: 'Código recebido:',
        recoveryTokenPlaceholder: 'Código enviado no seu e-mail',
        company: 'Empresa',
        companyAll: 'Todas',
        continueButton: 'ENVIAR E-MAIL DE RECUPERAÇÃO',
        finish: 'ALTERAR SENHA',
        welcome: 'Bem vindo(a) novamente.',
        goToHome: 'para voltar para o login, ',
        goToHomeAction: 'Clique aqui',
      },
      errors: {
        invalid_confirm: 'A senha e confirmação de senha devem ser iguais',
        fields: 'Verifique os campos e tente novamente',
        invalid_credentials:
          'Código inválido, por favor, verifique e tente novamente.',
        user_not_found: 'Credenciais inválidas, verifique e tente novamente.',
        user_no_access:
          'Você não tem acesso a essa tela. Entre em contato com um administrador.',
        user_invalid_token: 'Token inválido ou expirado. Entre novamente.',
      },
      messages: {
        recoverySent:
          'Acesse seu e-mail e siga as instruções para alterar a senha.',
        welcome: 'Bem vindo(a) novamente.',
        success: 'Sua senha foi alterada com sucesso.',
      },
    },
    passwordChanged: {
      url: '/senha-atualizada',
      content: {
        title: 'Senha atualizada com sucesso.',
        backToLogin: 'Voltar para o login',
      },
    },

    modalDetails: {
      contentDetail: {
        title: 'Detalhes do conteúdo',
        content: 'Conteúdo: ',
        module: 'Módulo: ',
        courseTitle: 'Título do curso: ',
      },
      userDetail: {
        title: 'Dados do aluno',
        name: 'Nome: ',
        cpf: 'CPF: ',
        phoneNumber: 'Telefone:',
        profission: 'Profissão: ',
        email: 'Email: ',
        city: 'Cidade: ',
        state: 'Estado: ',
        address: 'Endereço: ',
        cep: 'CEP: ',
      },
      questionDetail: {
        title: 'Detalhes da questão',
      }
    },

    panel: {
      pageTitle: 'Dashboard',
      administrative: 'Administrativo',
      userQuiz: {
        url: '/painel/estatisticas/%{userId}/simulados',
        pageTitle: 'Simulados do Usuário',
        pageName: 'Simulados',
        student: 'Aluno',
        dataTable: {
          columns: {
            name: {
              key: 'name',
              title: 'Nome'
            },
            executionDate: {
              key: 'users',
              title: 'Data de execução'
            },
            status: {
              key: 'users',
              title: 'Status'
            },
            hitPercentage: {
              key: 'users',
              title: 'Porcentagem de acerto'
            }
          }
        }
      },
      administrators: {
        sidebarTitle: 'Administradores',
        url: '/painel/administradores/',
        pageTitle: 'Administradores',
        deleteSucces: 'Administrador removido com sucesso.',
        addNewButtonText: 'Adicionar novo',
        dataTable: {
          columns: {
            photoUrl: {
              key: 'photoUrl',
            },
            name: {
              key: 'name',
              title: 'Nome',
            },
            email: {
              key: 'email',
              title: 'E-mail',
            },
            profileType: {
              key: 'profileType',
              title: 'Tipo de conta',
            },
            createdAt: {
              key: 'createdAt',
              title: 'Data de cadastro',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToDetailsText: 'Detalhes',
              goToEditText: 'Editar',
              removeText: 'Remover',
            },
          },
        },
      },
      users: {
        sidebarTitle: 'Usuários',
        sidebarQtd: 'Quantidade listada',
        sidebarItemRegister: 'Cadastrar',
        sidebarItemList: 'Alunos',
        kanbanList: 'Kanban',
        url: '/painel/usuarios/',
        pageTitle: 'Alunos',
        addNewButtonText: 'Adicionar novo',
        userImportCsvButtonText: 'Importar CSV',
        deleteSucces: 'Usuário removido com sucesso.',
        matches: 'matches',
        match: 'match',
        userImportCsvModal: {
          okText: 'Realizar upload',
          cancelText: 'Cancelar',
          title: 'Importar Usuários por CSV',
          templateButtonOne: 'Clique aqui',
          templateButtonTwo:
            'para realizar o download do template de importação.',
          selectFile: 'Clique aqui ou arraste arquivos',
          selectFileDescription: 'Tipo de arquivo permitido: .csv de até 2mb',
          errors: {
            invalidFileSize:
              'Arquivo muito grande, divida em um menor e tente novamente.',
            maxSelectedFiles: 'Apenas um arquivo por vez.',
            generic:
              'Algo deu errado ao tentar ler o arquivo, verifique e tente novamente.',
            genericUpload:
              'Algo deu errado ao tentar subir o arquivo, verifique e tente novamente.',
            login_already_used:
              'Já existe um Usuário com o login informado, verifique e tente novamente.',
            invalid_id: 'Selecione a empresa.',
          },
          success: 'Usuários(s) importado(s) com sucesso.',
        },
        dataTable: {
          columns: {
            photoUrl: {
              key: 'photoUrl',
            },
            name: {
              key: 'name',
              title: 'Nome',
            },
            email: {
              key: 'email',
              title: 'E-mail',
            },
            status: {
              key: 'status',
              title: 'Status',
            },
            cellphone: {
              key: 'cellphone',
              title: 'Celular',
            },
            planExpiry: {
              key: 'planExpiry',
              title: 'Expiração',
            },
            userCourseExpiry: {
              key: 'userCourse',
              title: 'Expiração do curso',
            },
            city: {
              key: 'city',
              title: 'Cidade',
            },
            cep: {
              key: 'cep',
              title: 'Cep',
            },
            specializations: {
              key: 'specializations',
              title: 'Especialização',
            },
            plan: {
              key: 'planOption',
              title: 'Plano',
            },
            coursePlan: {
              key: 'userCourse',
              title: 'Plano do curso',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToStatisticsText: 'Estatísticas',
              goToDetailsText: 'Detalhes',
              goToEditText: 'Editar',
              removeText: 'Remover',
            },
          },
        },
        errors: {
          generic: 'Algo deu errado, tente novamente mais tarde.',
        },
      },
      userInfo: {
        url: '/painel/usuario/detalhe/',
        pageTitle: 'Detalhe do usuário',
        seeStatistics: 'Ver estatísticas do usuário',
        tableHeader: {
          userData: 'Dados do usuário',
          userProfessionalData: 'Dados profissionais',
          others: 'Outros',
          courses: 'Cursos',
        },
        messages: {
          success: 'Usuário atualizado com sucesso.',
          errors: {
            generic: 'Algo deu errado, verifique os campos tente novamente.',
          },
        },
        actions: {
          editUser: 'Editar usuário',
          removeUser: 'Excluir usuário',
        },
      },
      contents: {
        sidebarTitle: 'Conteúdo',
        sidebarItemRegister: 'Cadastrar',
        sidebarItemList: 'Lista',
        url: '/painel/conteudo/',
        pageTitle: 'Conteúdo',
        addNewButtonText: 'Adicionar novo',
        deleteSucces: 'Conteúdo removido com sucesso.',
        dataTable: {
          columns: {
            studyType: {
              key: 'studyType',
              title: 'Tipo do conteúdo',
            },
            title: {
              key: 'title',
              title: 'Nome do conteúdo',
            },
            isEnabled: {
              key: 'isEnabled',
              title: 'Disponível',
            },
            categories: {
              key: 'categories',
              title: 'Categoria principal',
            },
            course: {
              key: 'course',
              title: 'Curso relacionado',
            },
            createdAt: {
              key: 'createdAt',
              title: 'Data de criação',
            },
            modules: {
              key: 'modules',
              titles: {
                course: 'Curso',
                module: 'Módulo',
                postDate: 'Data de exibição',
                company: 'Empresa',
              },
            },

            industry: {
              key: 'industry',
              title: 'Indústria',
            },
            theme: {
              key: 'theme',
              title: 'Tema',
            },
            article: {
              key: 'article',
              title: 'Artigo',
            },
            localAndYear: {
              key: 'localAndYear',
              title: 'Local/Ano',
            },
            author: {
              key: 'author',
              title: 'Autor',
            },
            updatedAt: {
              key: 'updatedAt',
              title: 'Data',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToStatisticsText: 'Estatísticas',
              goToEditText: 'Editar',
              removeText: 'Remover',
            },
          },
        },
        errors: {
          generic: 'Algo deu errado, tente novamente mais tarde.',
        },
      },
      courses: {
        sidebarTitle: 'Cursos',
        sidebarItemRegister: 'Cadastrar',
        sidebarItemList: 'Lista',
        url: '/painel/cursos/',
        pageTitle: 'Cursos',
        addNewButtonText: 'Adicionar novo',
        deleteSucces: 'Curso removido com sucesso.',
        filters: {
          chart: {
            label: 'Mostrar no gráfico'
          }
        },
        dataTable: {
          columns: {
            title: {
              key: 'title',
              title: 'Nome do curso',
            },
            moduleCount: {
              key: 'moduleCount',
              title: 'Quantidade de módulos',
              pdfFileName: 'qtd_modulos'
            },
            userCount: {
              key: 'userCount',
              title: 'Quantidade de usuários',
              pdfFileName: 'qtd_usuarios'
            },
            company: {
              key: 'company',
              title: 'Empresa',
            },
            isEnabled: {
              key: 'isEnabled',
              title: 'Disponível',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToEditText: 'Editar',
              removeText: 'Remover',
              goToCalendar: 'Agenda',
              goToUsers: 'Ver usuários',
              orderContents: 'Ordenar Conteúdos',
            },
          },
        },
        errors: {
          generic: 'Algo deu errado, tente novamente mais tarde.',
        },
      },
      modules: {
        sidebarTitle: 'Módulos',
        sidebarItemList: 'Lista',
        url: '/painel/modulos/',
        pageTitle: 'Módulos',
        addNewButtonText: 'Adicionar novo',
        deleteSucces: 'Módulo removido com sucesso.',
        dataTable: {
          columns: {
            title: {
              key: 'title',
              title: 'Nome do módulo',
            },
            course: {
              key: 'course',
              title: 'Curso relacionado',
            },
            createdAt: {
              key: 'createdAt',
              title: 'Data da criação',
            },
            isEnabled: {
              key: 'isEnabled',
              title: 'Disponível',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToEditText: 'Editar',
              removeText: 'Remover',
              goToDetailsText: 'Detalhes',
            },
          },
        },
        errors: {
          generic: 'Algo deu errado, tente novamente mais tarde.',
        },
      },
      moduleContents: {
        url: '/painel/modulos/',
        sufixUrl: '/conteudos',
        pageTitle: 'Conteúdos do módulo',
      },
      contentCalendar: {
        url: '/painel/agenda/',
        pageTitle: 'Agenda daily',
      },

      userRequests: {
        sidebarTitle: 'Solicitações',
        url: '/painel/solicitacoes/',
        pageTitle: 'Solicitações',
        kanban: {
          registrationsMade: 'cadastros realizados',
          specialization: 'Especialização: ',
          email: 'E-mail: ',
          TEMI: 'TEMI: ',
          UTI_AMIB: 'UTI AMIB: ',
          location: 'Localização: ',
          seeMore: 'Ver mais',
        },
        errors: {
          generic: 'Algo deu errado, tente novamente mais tarde.',
        },
        aproveModal: {
          title: "Digite abaixo a data de vencimento do plano para este aluno"
        }
      },

      statistics: {
        sidebarTitle: 'Estatísticas',
        sidebarItemList: 'Lista',
        url: '/painel/dashboard/estatisticas/',
        pageTitle: 'Estatísticas',
        userInStatistics: 'Usuários - Estatísticas',
        pageSubtitle: 'Ranking de conteúdos mais acessados',
        welcome: 'Bem-vindo de volta',
        progress: 'Confira nossos avanços!',
        currentUsers: 'Cadastros ocorridos: ',
        cards: {
          lastWeekAccessCount: 'Acessos na semana passada',
          lastMonthAccessCount: 'Acessos no mês passado',
          totalUsersWhoAccessCount: 'Total de usuários que acessaram',
          postedContentCount: 'Conteúdos postados',
          viewedContentCount: 'Quantidade de acessos',
          todayAccessCount: 'Acessos hoje',
          notApply: 'Não se aplica',
        },
        dataTable: {
          columns: {
            position: {
              key: 'position',
              title: 'Posição',
            },
            accessCount: {
              key: 'accessCount',
              title: 'Acessos',
            },
            title: {
              key: 'title',
              title: 'Nome do conteúdo',
            },
            course: {
              key: 'course',
              title: 'Curso relacionado',
            },
            modules: {
              key: 'modules',
              titles: {
                course: 'Curso',
                module: 'Módulo',
                postDate: 'Data de exibição',
              },
            },
            updatedAt: {
              key: 'updatedAt',
              title: 'Data',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToEditText: 'Editar',
              removeText: 'Remover',
            },
          },
        },
        studentsTable: {
          columns: {
            name: {
              key: 'name',
              title: 'Nome',
            },
            email: {
              key: 'email',
              title: 'Email',
            },
            cellphone: {
              key: 'cellphone',
              title: 'Telefone',
            },
            company: {
              key: 'companies',
              title: 'Empresas',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              buttons: {
                statistics: 'Estatisticas',
                quiz: 'Simulados'
              }
            },
          }
        },
        modal: {
          description: 'Descrição da prova :',
          labelQuestion: 'Enunciado da questão :',
          notHaveDescription: 'Não contem descrição',
          notHavejustification: 'Não contem justificativa do professor',
          justification: 'Justificativa:',
          dateCorrection: 'Data de correção:',
          correctBy: 'Responsável:',
          labelQuestion: 'Questão',
        },
        errors: {
          allUser: 'Não foi possivel obter todos os usuário',
          generic: 'Algo deu errado, tente novamente mais tarde.',
        },
      },

      userStatistics: {
        sidebarItemList: 'Lista',
        url: '/painel/dashboard/estatisticas/usuario/',
        csvFileName: 'estatística_usuario.csv',
        pdfFileName: 'estatistica_usuario',
        pageTitle: 'Estatísticas de conteúdos',
        breadcrumb: 'Estatísticas',
        pageSubtitle: 'Lista de conteúdos',
        bottomTitle: 'Conteúdos',
        welcome: 'Bem-vindo de volta',
        progress: 'Confira nossos avanços!',
        userTitle: 'Usuário',
        watched: {
          title: 'Status',
          watched: 'Assistido',
          notStarted: 'Não iniciado',
          inProgress: 'Em progresso',
          none: 'Tudo'
        },
        cards: {
          viewedContent: 'Abertura de conteúdo (visualização)',
          consumedContent: 'consumo por conteúdo',
          notViewedContent: 'Conteúdo não visualizado',
          lastAccess: 'Último acesso',
        },
        dataTable: {
          columns: {
            title: {
              key: 'content',
              title: 'Conteúdo',
            },
            modules: {
              key: 'content',
              titles: {
                course: 'Curso',
                module: 'Módulo',
                postDate: 'Data de exibição',
              },
            },
            viewedAt: {
              key: 'viewedAt',
              title: 'Acesso em',
            },
            viewed: {
              key: 'contentProgress',
              title: 'Assistido',
            },
          },
        },
        errors: {
          generic: 'Algo deu errado, tente novamente mais tarde.',
        },
      },

      contentStatistics: {
        sidebarItemList: 'Lista',
        url: '/painel/dashboard/estatisticas/conteudo/',
        pageTitle: 'Dashboard',
        breadcrumb: 'Conteúdo',
        pageSubtitle: 'Conteúdo',
        userTitle: 'Estatística do conteúdo',
        progress: 'Confira nossos avanços!',
        pageSubtitleConsumedUsers: 'Usuários que consumiram os conteúdos',
        undefinedData: 'Não definido',
        messages: {
          exportSuccess:
            'Usuários exportados. Em breve você receberá um e-mail com os dados.',
          exportError:
            'Erro ao exportar o usuário. Tente novamente mais tarde.',
        },
        cards: {
          viewedContent: 'Concluído',
          notViewedContent: 'Não concluído',
          lastAccess: 'Último acesso',
        },
        dataTable: {
          columns: {
            photoUrl: {
              key: 'photoUrl',
            },
            name: {
              key: 'name',
              title: 'Nome',
            },
            email: {
              key: 'email',
              title: 'E-mail',
            },
            status: {
              key: 'status',
              title: 'Status',
            },
            cellphone: {
              key: 'cellphone',
              title: 'Celular',
            },
            cep: {
              key: 'cep',
              title: 'CEP',
            },
            city: {
              key: 'city',
              title: 'Cidade',
            },
            specializations: {
              key: 'specializations',
              title: 'Especialização',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToStatisticsText: 'Estatísticas',
              goToDetailsText: 'Detalhes',
              goToEditText: 'Editar',
              removeText: 'Remover',
            },
          },
        },
        errors: {
          generic: 'Algo deu errado, tente novamente mais tarde.',
        },
      },

      statisticsList: {
        url: '/painel/dashboard/estatisticas/lista/',
        csvFileName: 'estatística.csv',
        type: {
          week: 'semana',
          month: 'mes',
          day: 'dia',
          users: 'usuarios',
          viewContent: 'visualizados',
          publishedContent: 'publicados',
        },
        pageTitle: 'Estatísticas',
        models: {
          title: 'Comentário do Professor (opcional):',
          title_student: 'Resposta do aluno :',
          notHaveAnswer: 'Não contem resposta para essa pergunta',
        },
        dataTable: {
          columns: {
            name: {
              key: 'name',
              title: 'Nome',
            },
            company: {
              key: 'companies',
              title: 'Empresa',
            },
            accessCount: {
              key: 'accessCount',
              title: 'Qtd de acessos',
            },
            title: {
              key: 'title',
              title: 'Nome',
            },
            course: {
              key: 'course',
              title: 'Curso relacionado',
            },
            modules: {
              key: 'modules',
              titles: {
                course: 'Curso',
                module: 'Módulo',
                postDate: 'Data de exibição',
              },
            },
            content: {
              key: 'content',
              title: 'Nome do conteúdo',
            },
            author: {
              key: 'author',
              title: 'Publico por',
            },
            percentage: {
              key: 'percentage',
              title: '% de conclusão',
            },
            lastAccess: {
              key: 'lastAccess',
              title: 'Último acesso',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToEditText: 'Editar',
              removeText: 'Remover',
              goToDetailsText: 'Detalhes',
              calculate: 'Simulado corrigido com sucesso ',
            },
          },
        },
        errors: {
          notCalculate: 'Não foi possivel finalizar a correção',
          generic: 'Algo deu errado, tente novamente mais tarde.',
        },
      },

      configuration: {
        sidebarTitle: 'Configurações',
        url: '/painel/configuracoes/',
        pageTitle: 'Configurações',
      },

      categories: {
        sidebarTitle: 'Categorias',
        sidebarItemList: 'Lista',
        url: '/painel/configuracoes/categorias/',
        pageTitle: 'Categorias',
        addNewButtonText: 'Adicionar nova',
        deleteSucces: 'Categoria removida com sucesso.',
        dataTable: {
          columns: {
            photoUrl: {
              key: 'photoUrl',
              title: 'Ícones',
            },
            title: {
              key: 'title',
              title: 'Nome da categoria',
            },
            contentCount: {
              key: 'contentCount',
              title: 'Conteúdos da categoria',
            },
            createdAt: {
              key: 'createdAt',
              title: 'Data de criação',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToEditText: 'Editar',
              removeText: 'Remover',
              goToDetailsText: 'Detalhes',
            },
          },
        },
        errors: {
          generic: 'Algo deu errado, tente novamente mais tarde.',
        },
      },

      hospitals: {
        sidebarTitle: 'Hospitais',
        sidebarItemList: 'Lista',
        url: '/painel/configuracoes/hospitais/',
        pageTitle: 'Hospitais',
        addNewButtonText: 'Adicionar novo',
        deleteSucces: 'Hospital removido com sucesso.',
        dataTable: {
          columns: {
            name: {
              key: 'name',
              title: 'Nome do hospital',
            },
            phone: {
              key: 'phone',
              title: 'Telefone',
            },
            cep: {
              key: 'cep',
              title: 'CEP',
            },
            city: {
              key: 'city',
              title: 'Cidade',
            },
            createdAt: {
              key: 'createdAt',
              title: 'Data da criação',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToEditText: 'Editar',
              removeText: 'Remover',
              goToDetailsText: 'Detalhes',
            },
          },
        },
        errors: {
          generic: 'Algo deu errado, tente novamente mais tarde.',
        },
      },

      companies: {
        sendQuizModal: {
          onText: 'Desejo ir para lá',
          cancelText: 'Voltar para a listagem',
          content: {
            one: 'Você ainda não tem alunos atribuídos neste simulado, para fazer isso, inclua os alunos pelo botão "atribuir alunos" na listagem de simulados.',
            two: 'Ou se preferir, clique no botão abaixo e vá direto para lá.',
          },
        },
        sidebarTitle: 'Empresas',
        sidebarItemList: 'Lista',
        url: '/painel/configuracoes/empresas/',
        pageTitle: 'Empresas',
        addNewButtonText: 'Adicionar nova',
        deleteSucces: 'Empresa removida com sucesso.',
        dataTable: {
          columns: {
            name: {
              key: 'name',
              title: 'Nome da empresa',
            },
            phone: {
              key: 'phone',
              title: 'Telefone',
            },
            cep: {
              key: 'cep',
              title: 'CEP',
            },
            city: {
              key: 'city',
              title: 'Cidade',
            },
            userCount: {
              key: 'userCount',
              title: 'Quantidade de usuários',
            },
            createdAt: {
              key: 'createdAt',
              title: 'Data da criação',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToEditText: 'Editar',
              removeText: 'Remover',
              goToDetailsText: 'Detalhes',
              goToUsers: 'Ver usuários',
            },
          },
        },
        errors: {
          generic: 'Algo deu errado, tente novamente mais tarde.',
        },
      },

      questionDatabase: {
        assign: 'Atribuir aluno(s)',
        assignUserQuestionDatabase: 'Inserir aluno no banco de questões',
        assignUser: 'Atribuir aluno',
        title: 'Banco de questões - Alunos matriculados',
        noAssign: 'Usuário não atribuído',
        sidebarTitle: 'Banco de questões',
        url: '/painel/banco-de-questoes/',
        modal: {
          userData: 'Dados do usuário',
        },
        corrections: {
          url: '/painel/banco-de-questoes-correcoes',
          sideBarTitle: 'Correções',
          pageTitle: 'Correções do banco de questões',
          noDissertationQuestion: 'Não contém questões dissertativas',
          noPermission: 'Você não tem permissão para realizar a correção',
          toCorrect: 'Corrigir',
          corrected: 'Corrigido',
          correct: 'Correto',
          incorrect: 'Incorreto',
          alternativeImage: 'Imagem da alternativa',
          finishCorrection: 'Finalizar correção',
          modal: {
            title: 'Descrição da questão:',
            showJustification: 'Mostrar justificativa',
            closeJustification: 'Fechar justificativa',
            noHaveJustification: 'Não contem justificativa do professor',
            correct: 'Correto',
            incorrect: 'Incorreto',
          },
        },
        chat: {
          haveNotMessage: 'Não há mensagens aqui',
          senFile: 'Enviou um arquivo',
          url: '/painel/banco-de-questoes/chat',
          pageTitle: 'Mensagens - Banco de questões',
          filter: {
            userName: 'Nome do usuário',
            question: 'Questão',
            noRead: 'Não lidas',
            read: 'Lidas',
            all: 'Todas',
            startAt: 'Data inicial',
            endAt: 'Data final',
            buttonFilter: 'Filtrar',
            buttonCleanFilter: 'Limpar filtros',
          },
          dataTable: {
            seeChatDetails: 'Ver detalhes da conversa',
            deleteChat: 'Deletar conversa',
          },
        },
        editNote: 'Editar nota',
        unlinkUserQuestionDatabase: 'Desvinvcular usuário do banco de questões',
        uncheckUserQuestionDatabase: 'Desmarque para desvincular usuário do banco de questões',
        unlinkUser: 'Desvincular usuário',
        confirmUnlinkUser: 'Tem certeza que deseja desvincular usuário?',
        subMenu: {
          item: {
            userQuestionDatabaseList: 'Lista de usuários',
            chatQuestionDatabase: 'Mensagens',
          },
        },
        assignDetails: {
          title: 'Informações da atribuição',
          form: {
            startAt: 'Data inicial',
            endAt: 'Data final',
            planOption: 'Plano do usuário',
            assignUser: 'Usuário atribuido:',
            noAssignUser: 'Usuário não atribuido',
          },
          assign: 'Atribuir',
          planOption: 'Plano do banco de questões',
        },
        questionDatabaseAssign: {
          url: '/painel/banco-de-questoes/atribuir',
          pageTitle: 'Atribuir usuário(s)',
        },
        pageTitle: 'Banco de questões',
        form: {
          title: {
            label: 'Título da versão',
          },
          description: {
            label: 'Descrição do que há de novo nessa versão',
          },
          numberOfVersion: {
            label: 'Número da versão'
          },
        },
        messages: {
          error: {
            invalidUsers: 'Selecione um usuário',
            invalidPlan: 'Selecione um plano para o banco de questões!',
            request: 'Não foi possível carregar os usuários do banco. Tente novamente!',
            requestCorrections: 'Não foi possível carregar as questões para correção. Tente novamente!',
            delete: 'Não foi possível desvincular o usuário do banco. Tente novamente!',
            assigned: 'Não foi possível concluir a atribuição. Tente novamente!',
            invalidDate: 'Insira uma data para o banco de questões!',
            errorCorrectQuestion: 'Não foi possível corrigir esta questão. Tente novamente!',
            selectCompany: 'Selecione pelo menos uma empresa',
          },
          success: {
            assigned: 'Banco aribuído com sucesso',
            deleted: 'Usuário desvinculado do banco com sucesso',
            corrected: 'Questão corrigida com sucesso!',
          }
        },
        dataTableCorrections: {
          filters: {
            userName: 'Nome',
            userEmail: 'E-mail',
            status: 'Status',
            toCorrect: 'Não corrigido',
            corrected: 'Corrigido'
          },
          columns: {
            questionNumber: {
              key: 'answer',
              title: 'Número da questão',
            },
            userName: {
              key: 'user',
              title: 'Nome do usuário',
            },
            userEmail: {
              key: 'user',
              title: 'Email do usuário',
            },
            answerDate: {
              key: 'createdAt',
              title: 'Data da resposta',
            },
            correctionStatus: {
              key: 'status',
              title: 'Status da correção'
            },
            userCorrector: {
              key: 'userCorrector',
              title: 'Responsável da correção'
            },
            companies: {
              key: 'question',
              title: 'Empresa'
            }
          }
        },
        dataTable: {
          assignToAll: 'Atribuir para todos',
          filters: {
            userName: 'Nome',
            company: 'Empresa',
            email: 'E-mail',
          },
          columns: {
            planOption: {
              key: 'planOption',
              title: 'Plano',
            },
            planExpiry: {
              key: 'endAt',
              title: 'Expiração'
            },
            photoUrl: {
              key: 'user',
            },
            userName: {
              key: 'user',
              title: 'Nome'
            },
            userEmail: {
              key: 'user',
              title: 'E-mail',
            },
            company: {
              key: 'company',
              title: 'Empresa'
            },
            cellphone: {
              key: 'user',
              title: 'Celular',
            },
            statusCorrection: 'Status da correção',
          },
        },
      },

      releaseNotes: {
        sidebarTitle: 'Notas das versões',
        url: '/painel/notas/',
        editNote: 'Editar nota',
        noteDetails: {
          url: '/painel/nota/detalhe/',
          pageTitle: 'Detalhes da versão',
        },
        pageTitle: 'Notas das versões',
        form: {
          pageTitle: 'Detalhes da versão',
          title: {
            label: 'Título da versão',
          },
          description: {
            label: 'Descrição do que há de novo nessa versão',
          },
          numberOfVersion: {
            label: 'Número da versão'
          },
        },
        messages: {
          error: {
            invalidTitle: 'Insira um título',
            invalidDescription: 'Insira uma descrição',
            invalidVersion: 'Insira uma versão',
            invalidVersionLength: 'Insira uma versão válida',
            generic: 'Não foi possível enviar sua nota. Tente novamente!',
            request: 'Não foi possível carregar as notas. Tente novamente!',
            details: 'Não foi possível carregar os detalhes da nota. Tente novamente!',
            editNote: 'Não foi possível editar essa nota. Tente novamente!',
          },
          success: {
            created: 'Nota enviada com sucesso',
            deleted: 'Nota deletada com sucesso',
            editNote: 'Nota editada com sucesso',
          }
        },
        dataTable: {
          addNew: 'Adicionar nova',
          filters: {
            title: 'Título da versão',
            version: 'Número da versão',
          },
          columns: {
            title: {
              key: 'title',
              title: 'Título da versão'
            },
            version: {
              key: 'version',
              title: 'Número da versão',
            },
            description: {
              key: 'description',
              title: 'Descrição',
            },
          }
        }
      },

      pushNotifications: {
        sidebarTitle: 'Notificações',
        sidebarItemList: 'Lista',
        url: '/painel/notificacoes/',
        pageTitle: 'Notificações',
        addNewButtonText: 'Adicionar nova',
        deleteSucces: 'Notificação removida com sucesso.',
        dataTable: {
          columns: {
            title: {
              key: 'title',
              title: 'Título',
            },
            scheduleDate: {
              key: 'scheduleDate',
              title: 'Data do envio',
            },
            userCount: {
              key: 'userCount',
              title: 'Destinatários',
            },
            createdAt: {
              key: 'createdAt',
              title: 'Data da criação',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToEditText: 'Editar',
              removeText: 'Remover',
              goToDetailsText: 'Detalhes',
            },
          },
        },
        errors: {
          generic: 'Algo deu errado, tente novamente mais tarde.',
        },
      },

      chat: {
        sidebarTitle: 'Mensagens',
        sidebarItemList: 'Lista',
        url: '/painel/chat/',
        title: 'Perguntas sobre a Prova',
        pageTitle: 'Perguntas sobre a Prova',
        addNewButtonText: 'Adicionar nova',
        deleteSucces: 'Notificação removida com sucesso.',
        messages: {
          delete: 'Deseja mesmo deletar a conversa com ',
        },
        dataTable: {
          columns: {
            items: {
              student: 'Aluno',
              email: 'Email',
              answers: 'Questão',
              messege: 'Mensagem',
              date: 'Data',
              answerDate: 'Data Resposta Professor',
              answerTeacher: 'Responsável da resposta',
              noAnswerred: 'Não respondido',
            },
            title: {
              key: 'title',
              title: 'Título',
            },
            scheduleDate: {
              key: 'scheduleDate',
              title: 'Data do envio',
            },
            userCount: {
              key: 'userCount',
              title: 'Destinatários',
            },
            createdAt: {
              key: 'createdAt',
              title: 'Data da criação',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToEditText: 'Editar',
              removeText: 'Remover',
              goToDetailsText: 'Detalhes',
            },
          },
        },
        errors: {
          generic: 'Algo deu errado, tente novamente mais tarde.',
        },
        chatList: {
          content: {
            contentTitle: 'Título do conteúdo',
          }
        },
      },
      chatList: {
        content: {
          contentTitle: 'Título do conteúdo',
        }
      },
      contentChat: {
        sidebarTitle: 'Conteúdo',
        sidebarItemList: 'Lista',
        url: '/painel/chat/conteudo/',
        pageTitle: 'Perguntas sobre Conteúdos',
        sendMessage: 'Enviou um arquivo',
        notMessage: 'Não há mensagens aqui',
      },
      userDetails: {
        url: '/painel/usuarios/',
        sufixUrl: '/curso',
        sufixCompaniesUrl: '/empresa',
        pageTitle: 'Detalhes do Usuário',
        pageTitleAdd: 'Cadastrar Usuário',
        pageDescription: 'Preencha os campos abaixo para editar um Usuário.',
        pageDescriptionAdd:
          'Preencha os campos abaixo para adicionar um novo Usuário',
        changePasswordPhrase: 'Desejo alterar a senha do Usuário',
        noCourses: 'Nenhum curso adicionado',
        invalidUser: 'Adicione um curso ou atribua o usuário ao banco de questões',
        addedCourses: 'Cursos adicionados:',
        addedQuestions: 'Questões adicionadas',
        csvFileName: 'questões.csv',
        planOptionCourse: 'Plano do curso',
        pdf: {
          exportData: 'Exportar dados em pdf',
          exportAllData: 'Exportar todos os dados em pdf',
        },
        messages: {
          success: 'Usuário atualizado com sucesso.',
          successCreate: 'Usuário adicionado com sucesso.',
          errors: {
            cellphone_already_registerd: 'Celular já cadastrado',
            cpf_already_registerd: 'CPF já cadastrado',
            email_already_used: 'E-mail já cadastrado',
            invalid_password: 'Senha inválida (mínimo de 6 caracteres).',
            invalid_email: 'E-mail inválido, verifique e tente novamente.',
            invalid_telephone: 'Telefone inválido',
            invalid_plan_expiry: 'Data de vencimento inválida',
            invalid_city: 'Cidade inválida',
            invalid_state: 'Estado inválido',
            invalid_neighborhood: 'Bairro inválido',
            invalid_address: 'Endereço inválido',
            invalid_cep: 'CEP inválido',
            without_cep: 'Insira um CEP válido',
            invalid_cpf: 'CPF inválido',
            invalid_ddi: 'DDI inválido',
            invalid_cellphone: 'Celular inválido',
            invalid_name: 'Nome inválido (mínimo 5 caracteres).',
            generic: 'Algo deu errado, verifique os campos tente novamente.',
            passwordsMismatch:
              'A senha e confirmação de senha não estão iguais.',
            passwordsRequired: 'A senha e confirmação de senha são requeridas.',
            invalid_image_type: 'Formatos permitidos: jpeg, jpg e png',
            invalid_image_size: 'O tamanho máximo do arquivo é de 30MB',
            courseAlreadyAdd: 'Este curso já está adicionado',
            withoutCompany: 'O usuário precisa estar vinculado à uma empresa',
            before_date: 'A data final tem que ser maior que a inicial',
            invalid_date: 'Insira uma data válida',
            invalid_plan: 'Insira um plano para o curso',
            startAt_invalid: 'A data inicial não pode ser menor que a atual',
          },
        },
      },
      administratorDetails: {
        url: '/painel/administradores/',
        pageTitle: 'Detalhes do administrador',
        pageTitleAdd: 'Criar administrador',
        pageDescription:
          'Preencha os campos abaixo para editar um administrador.',
        pageDescriptionAdd:
          'Preencha os campos abaixo para adicionar um novo administrador',
        changePasswordPhrase: 'Desejo alterar a senha do administrador',
        csvFileName: 'administradores.csv',
        messages: {
          success: 'Administrador atualizado com sucesso.',
          successCreate: 'Usuário adicionado com sucesso.',
          errors: {
            generic: 'Algo deu errado, verifique os campos tente novamente.',
            email: 'E-mail inválido, verifique e tente novamente.',
            passwordsMismatch:
              'A senha e confirmação de senha não estão iguais.',
            passwordsRequired: 'A senha e confirmação de senha são requeridas.',
          },
        },
      },
      contentDetails: {
        url: '/painel/conteudo/',
        urlDuplicate: '/painel/conteudo/duplicate/',
        pageTitle: 'Detalhe do conteúdo',
        pageTitleAdd: 'Cadastrar conteúdo',
        pageDescription: 'Preencha os campos abaixo para editar um conteúdo.',
        pageDescriptionAdd:
          'Preencha os campos abaixo para adicionar um novo conteúdo',
        noCourses: 'Nenhum curso/módulo adicionado',
        addedCourses: 'Cursos e módulos adicionados',
        csvFileName: 'conteudos.csv',
        messages: {
          success: 'Conteúdo atualizado com sucesso.',
          successCreate: 'Conteúdo adicionado com sucesso.',
          errors: {
            generic: 'Algo deu errado, verifique os campos tente novamente.',
            invalid_post_date: 'A data de exibição é obrigatória',
            postWithoutContent: 'O conteúdo precisa ter pelo menos um arquivo',
            invalid_course_length: 'Selecione ao menos um curso e módulo',
            invalid_category_id: 'Categoria inválida',
            invalid_categories: 'Tags inválidas',
            invalid_audio_file_name: 'Áudio inválido',
            invalid_video_file_name: 'Vídeo inválido',
            invalid_base_article: 'Artigo inválido',
            invalid_year: 'Ano inválido',
            invalid_summary: 'Resumo inválido',
            invalid_description: 'Descrição inválida',
            invalid_title: 'Título inválido',
            categoryList: 'É necessário selecionar ao menos uma categoria',
            mainCategory: 'É necessário selecionar uma categoria principal',
            courseAlreadyAdd: 'Este curso e módulo já estão adicionados',
          },
          fileSuccess: 'Arquivo adicionado com sucesso',
          fileError: 'Ocorreu um erro ao fazer o upload do arquivo',
        },
      },
      orderContents: {
        title: 'Ordenar Conteúdos',
        description: 'Arraste e solte os conteúdos abaixo para ordená-los',
        fields: {
          order: 'Ordem',
          title: 'Título',
          year: 'Ano',
          categories: 'Categorias',
          date: 'Data'
        },
        buttons: {
          save: 'Salvar Ordenação'
        },
        errors: {
          saveFail: 'Não foi possível salvar esta ordenação',
          saveSuccess: 'Ordenação Salva com Sucesso'
        },
        modulesMode: 'Ordenar Módulos',
        contentsMode: 'Ordenar Conteúdos',
        orderTypesTooltip: {
          module: 'Se você optar por ordenar por módulos, estará alterando a ordenação do núcleo do módulo e não dos conteúdos individuais dentro dele, certo?',
          content: 'Se escolher ordenar os conteúdos que estão dentro dos módulos, estará alterando a ordenação desses conteúdos que pertencem a algum módulo específico.',
        }
      },
      courseDetails: {
        url: '/painel/curso/',
        urlDuplicate: '/painel/curso/duplicate/',
        pageTitle: 'Detalhe do curso',
        pageTitleAdd: 'Cadastrar curso',
        pageDescription: 'Preencha os campos abaixo para editar um curso.',
        pageDescriptionAdd:
          'Preencha os campos abaixo para adicionar um novo curso',
        csvFileName: 'cursos.csv',
        confirmDuplicate: 'Tem certeza que deseja duplicar todos os módulos e conteúdos?',
        messages: {
          success: 'Curso atualizado com sucesso.',
          successCreate: 'Curso adicionado com sucesso.',
          successDuplicate: 'Curso duplicado com sucesso.',
          errors: {
            generic: 'Algo deu errado, verifique os campos tente novamente.',
            invalid_image_file_name: 'Imagem inválida',
            invalid_video_file_name: 'Vídeo inválido',
            invalid_image_type: 'Formatos permitidos: jpeg, jpg e png',
            invalid_image_size: 'O tamanho máximo do arquivo é de 30MB',
            invalid_description: 'Descrição inválida',
            can_only_exist_one_trial: 'Já existe curso trial:',
            invalid_title: 'Título inválido',
            invalid_cover_url: 'Arquivo inválido',
          },
          fileSuccess: 'Arquivo adicionado com sucesso',
          fileError: 'Ocorreu um erro ao fazer o upload do arquivo',
        },
      },
      orderCourses: {
        url: '/painel/curso/ordenar/',
        pageTitle: 'Ordenar Cursos'
      },
      moduleDetails: {
        url: '/painel/modulo/',
        urlDuplicate: '/painel/modulo/duplicate/',
        pageTitle: 'Detalhe do modulo',
        pageTitleAdd: 'Cadastrar modulo',
        pageDescription: 'Preencha os campos abaixo para editar um modulo.',
        pageDescriptionAdd:
          'Preencha os campos abaixo para adicionar um novo modulo',
        csvFileName: 'modulos.csv',
        messages: {
          success: 'Modulo atualizado com sucesso.',
          successCreate: 'Modulo adicionado com sucesso.',
          errors: {
            generic: 'Algo deu errado, verifique os campos tente novamente.',
            invalid_image_file_name: 'Imagem inválida',
            invalid_video_file_name: 'Vídeo inválido',
            invalid_description: 'Descrição inválida',
            invalid_image_type: 'Formatos permitidos: jpeg, jpg e png',
            invalid_image_size: 'O tamanho máximo do arquivo é de 30MB',
            invalid_title: 'Título inválido',
          },
          fileSuccess: 'Arquivo adicionado com sucesso',
          fileError: 'Ocorreu um erro ao fazer o upload do arquivo',
        },
      },
      userRequestDetails: {
        messages: {
          success: 'Atualizado sucesso.',
          errors: {
            generic: 'Algo deu errado, verifique os campos tente novamente.',
            date: 'Data de vencimento deve ser uma data futura.',
          },
        },
      },
      hospitalDetails: {
        url: '/painel/configuracoes/hospitais/',
        pageTitle: 'Detalhes do hospital',
        pageTitleAdd: 'Cadastrar hospital',
        pageDescription: 'Preencha os campos abaixo para editar um hospital.',
        pageDescriptionAdd:
          'Preencha os campos abaixo para adicionar um novo hospital',
        messages: {
          success: 'Hospital atualizado com sucesso.',
          successCreate: 'Hospital adicionado com sucesso.',
          errors: {
            invalid_type: 'Selecione o tipo de capital',
            invalid_email: 'E-mail inválido, verifique e tente novamente.',
            invalid_phone: 'Telefone inválido',
            invalid_city: 'Cidade inválida',
            invalid_number: 'Número inválido',
            invalid_address: 'Endereço inválido',
            invalid_state: 'Estado inválido',
            invalid_cep: 'CEP inválido',
            invalid_cnpj: 'CNPJ inválido',
            invalid_name: 'Nome inválido',
            generic: 'Algo deu errado, verifique os campos tente novamente.',
          },
        },
      },
      companyDetails: {
        url: '/painel/configuracoes/empresas/',
        pageTitle: 'Detalhes da empresa',
        pageTitleAdd: 'Cadastrar empresa',
        pageDescription: 'Preencha os campos abaixo para editar uma empresa.',
        pageDescriptionAdd:
          'Preencha os campos abaixo para adicionar uma nova empresa',
        messages: {
          success: 'Empresa atualizada com sucesso.',
          successCreate: 'Empresa adicionada com sucesso.',
          errors: {
            invalid_email: 'E-mail inválido, verifique e tente novamente.',
            invalid_phone: 'Telefone inválido',
            invalid_city: 'Cidade inválida',
            invalid_number: 'Número inválido',
            invalid_address: 'Endereço inválido',
            invalid_state: 'Estado inválido',
            invalid_cep: 'CEP inválido',
            invalid_cnpj: 'CNPJ inválido',
            invalid_name: 'Nome da empresa inválido',
            generic: 'Algo deu errado, verifique os campos tente novamente.',
          },
        },
      },
      categoryDetails: {
        url: '/painel/configuracoes/categorias/',
        pageTitle: 'Detalhes da categoria',
        pageTitleAdd: 'Cadastrar categoria',
        pageDescription: 'Preencha os campos abaixo para editar uma categoria.',
        pageDescriptionAdd:
          'Preencha os campos abaixo para adicionar uma nova categoria',
        messages: {
          success: 'Categoria atualizada com sucesso.',
          successCreate: 'Categoria adicionada com sucesso.',
          errors: {
            invalid_name: 'Nome inválido',
            invalid_image_type: 'Formatos permitidos: jpeg, jpg e png',
            invalid_image_size: 'O tamanho máximo do arquivo é de 30MB',
            generic: 'Algo deu errado, verifique os campos tente novamente.',
          },
        },
      },
      pushNotificationDetails: {
        url: '/painel/notificacoes/',
        pageTitle: 'Detalhes da notificação',
        pageTitleAdd: 'Cadastrar notificação',
        pageDescription: 'Visualização da notificação agendada.',
        pageDescriptionAdd:
          'Preencha os campos abaixo para agendar ou enviar uma nova notificação',
      },
      quiz: {
        urlQuiz: '/painel/provas/',
        urlQuizDuplicate: '/painel/provas/duplicate/',
        urlQuizStatistics: '/painel/provas/estatisticas/',
        urlQuizCorrections: '/painel/provas/estatisticas/correcao/',
        urlQuizAddUsers: '/painel/provas/usuarios/',
        urlQuestion: '/painel/questoes/',
        titleQuiz: 'Quiz',
        pageQuiz: 'Simulados',
        AssignStudents: 'Editar lista de alunos',
        pageQuestion: 'Questões',
        pageStatistics: 'Estatísticas',
        pageCorrections: 'Correção',
        statuCorrection: 'Status de correção',
        pageNewQuestion: 'Cadastrar nova questão',
        pageEditingQuestion: 'Edição de Questão',
        updateQuestion: 'Atualizado com sucesso',
        pageNewExame: 'Adicionar nova prova ou simulado',
        duality: {
          yes: 'Sim',
          no: 'Não'
        },
        notHaveAlternative:
          'É necessário escolher uma alternativa multipla escolha ou dissertativa, não deixe campo vazio',
        addNewQuiz: 'Adicionar nova prova',
        addQuestions: 'Adicionar alunos e questões',
        pageEditingExame: 'Edição de prova ou simulado',
        sidebarItemList: 'Questões',
        typeQuestion: 'Tipo de pergunta',
        newQuestion: 'Adicionar nova questão',
        newExame: 'Adicionar novo Simulado',
        exportData: 'Exportar dados',
        exportAllData: 'Exportar todos os dados',
        seeEstatistics: 'Ver estatísticas do Simulado',
        sucessRemovedQuestion: 'Questão removida com sucesso',
        messagesUpdateQuestion: 'Questão atualizada com sucesso!',
        messagesUpdateQuestionFail:
          'Erro ao atualizar a questão, tente mais tarde.',
        messagesUpdateQuiz: 'Simulado atualizada com sucesso!',
        messagesUpdateQuizFail:
          'Erro ao atualizar o simulado, tente mais tarde.',
        messageExportQuiz:
          'Simulados exportados com sucesso, confira seu e-mail!',
        messageExportStatistics:
          'Estatisticas do simulado exportados com sucesso, confira seu e-mail',
        messageExportStatisticsFail:
          'Erro ao exportar Estatisticas do simulado, tente mais tarde. ',
        messageExportQuestion:
          'Questões exportados com sucesso, confira seu e-mail!',
        messageExportQuestionFail: 'Erro ao exportar questões',
        dataTable: {
          columns: {
            orderQuestion: {
              title: 'Número da questão',
              key: 'number',
            },
            enumQuestion: {
              title: 'Enunciado questão',
              key: 'description',
            },
            name: {
              title: 'Nome simulado',
              key: 'name',
            },
            nameUser: {
              title: 'Nome do usuário',
              key: 'name',
            },
            year: {
              title: 'Ano',
              key: 'year',
            },
            lastAnswer: {
              key: 'updatedAt',
              title: 'Data de última resposta',
            },
            status: {
              key: 'status',
              title: 'Status',
            },
            difficulty: {
              title: 'Dificuldade',
              key: 'difficulty',
            },
            numberOfQuestions: {
              title: 'Questões',
              key: 'numberOfQuestions',
            },
            numberOfRanking: {
              title: 'Número',
              key: 'ranking',
            },
            description: {
              title: 'Descrição',
              key: 'description',
            },
            category: {
              title: 'Categoria Principal',
              key: 'categories',
            },
            categorySec: {
              title: 'Categoria Secundária',
              key: 'categories',
            },
            quizTime: {
              title: 'Tempo de Execução',
              key: 'userQuizTotalTime',
            },
            createdAt: {
              title: 'Criado em',
              key: 'createdAt',
            },
            createBy: {
              title: 'Criado por',
              key: 'createBy',
            },
            expirationAt: {
              title: 'Expiração',
              key: 'expirationAt',
            },
            location: {
              title: 'Local',
              key: 'location',
            },
            companies: {
              title: 'Empresa',
              key: 'companies',
            },
            isEnabled: {
              key: 'isEnabled',
              title: 'Disponível',
            },
            justification: {
              key: 'justification',
              title: 'Justificado',
            },
            corretion: {
              key: 'correctAnswersPercentage',
              title: 'Porcentagem de acerto',
            },
            toCorrect: {
              key: 'toCorrect',
              title: 'Correção',
              corrected: 'Corrigido',
              correct: 'Corrigir',
            },
            courseTitle: {
              key: 'title',
              title: 'Nome do curso',
            },
            id: {
              key: 'id',
              title: 'ID',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToStatisticsText: 'Estatísticas',
              goToDetailsText: 'Detalhes',
              goDuplicate: 'Duplicar',
              goAddUsers: 'Atribuir alunos',
              goToEditText: 'Editar',
              removeText: 'Remover',
            },
            error: {
              updateQuiz: 'Não foi possível atualizar a correção',
            },
          },
        },
      },
      assignStudents: {
        selectCompany: 'Selecione uma empresa',
        selectCourse: 'Selecione um ou mais cursos',
        buttons: {
          viewSelected: 'Ver selecionados',
          viewAll: 'Ver todos',
          conclude: 'Concluir',
        },
        sectionAddOrRemoveStudentsByCourses: {
          title: 'Adicionar ou remover alunos por curso',
          filters: {
            company: 'Selecione a empresa',
            courses: 'Selecione cursos',
          },
          buttons: {
            add: 'Adicionar',
            remove: 'Remover',
            select: 'Selecionar',
          },
          success: "Lista de alunos atualizada com sucesso",
        },
        dataTable: {
          columns: {
            name: {
              title: 'Nome',
            },
            email: {
              title: 'Email',
            },
            telephone: {
              title: 'Telefone',
            },
            specializations: {
              title: 'Especializações',
            },
            plan: {
              title: 'Plano',
            },
            city: {
              title: 'Cidade',
            },
          },
        },
      },
    },
  },
  forms: {
    user: {
      compay: {
        label: 'Empresa'
      },
      name: {
        label: 'Nome completo',
      },
      nameFilter: {
        label: 'Nome',
      },
      addImage: {
        label: 'Upload foto',
      },
      imageTitle: {
        label: 'Foto do perfil',
      },
      email: {
        label: 'E-mail',
      },
      gender: {
        label: 'Gênero',
      },
      bornAt: {
        label: 'Data de nascimento',
      },
      cpf: {
        label: 'CPF',
      },
      telephone: {
        label: 'Telefone',
      },
      cellphone: {
        label: 'Celular',
      },
      ddi: {
        label: 'DDI',
      },
      hasTemi: {
        label: 'Já prestou a prova TEMI',
      },
      plan: {
        label: 'Plano',
      },
      hasUtiExpertise: {
        label: 'Possui título de especialista UTI AMIB',
      },
      planExpiry: {
        label: 'Data de vencimento do plano',
      },
      category: {
        label: 'Categorização',
      },
      hospitals: {
        label: 'Hospitais / empresas que atua',
      },
      industry: {
        label: 'Indústria',
      },
      password: {
        label: 'Senha',
      },
      passwordConfirmation: {
        label: 'Confirmação da senha',
      },
      status: {
        label: 'Status de pagamento',
      },
      cep: {
        label: 'CEP',
      },
      neighborhood: {
        label: 'Bairro',
      },
      city: {
        label: 'Cidade',
      },
      state: {
        label: 'Estado',
      },
      address: {
        label: 'Endereço',
      },
      addressNumber: {
        label: 'Número',
      },
      complement: {
        label: 'Complemento',
      },
      company: {
        label: 'Empresa',
      },
      specializations: {
        label: 'Especialização',
      },
      hasPlatformAccess: {
        label: 'Liberar acesso a plataforma',
      },
      modules: {
        label: 'Módulos',
      },
      courses: {
        label: 'Cursos',
      },
      course: {
        label: 'Curso',
      },
      question: {
        label: 'Questões',
      },
      startAt: {
        label: 'Data inicial',
      },
      questionsDatabase: {
        label: 'Banco de questões',
      },
      endAt: {
        label: 'Data final',
      },
      btnAddCourse: {
        label: 'Adicionar este curso/módulo',
      },
      hint: 'Selecione os dados desejados e clique no (+) ao lado para adicionar.',
      professions: {
        title: 'Profissão',
        professionMedic: 'Médico',
        professionNurse: 'Enfermeiro',
        professionPhysiotherapist: 'Fisioterapeuta',
        professionOther: 'Outra',
        professionOtherLabel: 'Outra profissão',
      },
    },
    administrator: {
      name: {
        label: 'Nome completo',
      },
      email: {
        label: 'E-mail',
      },
      password: {
        label: 'Senha',
      },
      passwordConfirmation: {
        label: 'Confirmação da senha',
      },
      profileType: {
        title: 'Perfil do usuário',
        profileTypeAdmin: 'Administrador',
        profileTypeUser: 'Usuário',
        profileTypeOperator: 'Supervisor',
        profileTypeMarketing: 'Marketing',
        profileTypeQuizer: 'Quizer',
        profileTypeTeacher: 'Professor',
        profileTypeUploader: 'Uploader',
        profileTypeKanbanManager: 'Gerenciador de kanban',
      },
      profileTypeConfig: {
        configTypeAdmin: '(Administrar alunos, cursos, quizzes, mensagens, notificações, configurações, estatísticas)',
        configTypeOperator: '(Criar alunos, cursos, quizzes, mensagens, notificações, configurações, estatísticas)',
        configTypeMarketing: '(Criar alunos, notificações e ver estatísticas)',
        configTypeQuizer: '(Criar provas e questões)',
        configTypeTeacher: '(Criar provas, questões e responder alunos)',
        configTypeUploader: '(Criar cursos, conteúdos, provas e questões)',
        configTypeKanban: '(Gerenciamento de kanban de usuários)',
      }
    },
    content: {
      fileLarge: "O tamanho do arquivo é maior do que %{fileLimitInMegabytes}MB",
      steps: {
        contentData: {
          label: 'Dados do conteúdo',
        },
        resume: {
          label: 'Resumo do conteúdo',
        },
        files: {
          label: 'Arquivos',
        },
      },
      studyType: {
        label: 'Tags',
      },
      contentType: {
        label: 'Tipo de distribuição',
      },
      title: {
        label: 'Título',
      },
      description: {
        label: 'Descrição',
      },
      category: {
        label: 'Categoria secundária',
      },
      mainCategory: {
        label: 'Categoria',
      },
      industry: {
        label: 'Indústria',
      },
      theme: {
        label: 'Tema',
      },
      baseArticle: {
        label: 'Artigo base',
      },
      year: {
        label: 'Ano',
      },
      postDate: {
        label: 'Data de exibição:',
      },
      author: {
        label: 'Autor principal',
      },
      course: {
        label: 'Curso',
      },
      btnAddCourse: {
        label: 'Adicionar',
      },
      module: {
        label: 'Módulo',
      },
      summary: {
        label: 'Resumo do conteúdo',
        placeholder: 'Descrição do conteúdo',
      },
      addPdf: {
        label: 'adicionar pdf',
      },
      attachmentsPdf: {
        label: 'adicionar anexos',
      },
      addAudio: {
        label: 'adicionar áudio',
      },
      addVideo: {
        label: 'adicionar vídeo',
      },
      pdfTitle: {
        label: 'Resumo do conteúdo',
      },
      attachmentsTitle: {
        label: 'Anexos',
      },
      audioTitle: {
        label: 'Áudio Podcast',
      },
      videoTitle: {
        label: 'Vídeo',
      },
    },
    course: {
      steps: {
        courseData: {
          label: 'Dados do curos',
        },
        attachments: {
          label: 'Anexos do curso',
        },
      },
      supportsDaily: {
        label: 'Suporta Daily?',
      },
      isTrial: {
        label: 'É trial?',
      },
      isHomeVisible: {
        label: 'Visível nas tags home?',
      },
      title: {
        label: 'Título',
      },
      description: {
        label: 'Descritivo do curso',
      },
      company: {
        label: 'Empresa',
      },
      coverType: {
        label: 'Tipo de capa',
      },
      coverTypeImg: {
        label: 'Imagem',
      },
      coverTypeVideo: {
        label: 'Vídeo',
      },
      isEnabled: {
        label: 'Ativo?',
      },
      isDuplicating: {
        label: 'Duplicar todos os módulos e conteúdos?',
      },
      addImage: {
        label: 'Imagem jpg, jpeg ou png',
      },
      addVideo: {
        label: 'Vídeo mp4 ou avi',
      },
      imageTitle: {
        label: 'Imagem de fundo da capa',
      },
      videoTitle: {
        label: 'Vídeo de capa',
      },
    },
    module: {
      title: {
        label: 'Nome do módulo',
      },
      description: {
        label: 'Descritivo do módulo',
      },
      course: {
        label: 'Curso',
      },
      isEnabled: {
        label: 'Ativo?',
      },
      addImage: {
        label: 'Upload foto',
      },
      imageTitle: {
        label: 'Capa do Módulo',
      },
    },
    hospital: {
      name: {
        label: 'Nome do hospital',
      },
      email: {
        label: 'E-mail',
      },
      cnpj: {
        label: 'CNPJ',
      },
      cep: {
        label: 'CEP',
      },
      state: {
        label: 'Estado',
      },
      city: {
        label: 'Cidade',
      },
      address: {
        label: 'Endereço',
      },
      addressNumber: {
        label: 'Número',
      },
      phone: {
        label: 'Telefone',
      },
      extension: {
        label: 'Ramal',
      },
      type: {
        label: 'Tipo de capital',
      },
    },
    company: {
      name: {
        label: 'Nome da empresa ou grupo',
      },
      email: {
        label: 'E-mail',
      },
      description: {
        label: 'Descrição',
      },
      cnpj: {
        label: 'CNPJ',
      },
      cep: {
        label: 'CEP',
      },
      state: {
        label: 'Estado',
      },
      city: {
        label: 'Cidade',
      },
      address: {
        label: 'Endereço',
      },
      addressNumber: {
        label: 'Número',
      },
      phone: {
        label: 'Telefone',
      },
      responsible: {
        label: 'Canal de Contato',
      },
      extension: {
        label: 'Ramal',
      },
    },
    category: {
      title: {
        label: 'Nome da categoria',
      },
      addImage: {
        label: 'Upload foto',
      },
      imageTitle: {
        label: 'Capa da categoria',
      },
    },
    newQuestion: {
      description: 'Preencha os campos abaixo para adicionar uma nova questão',
      labelLocal: 'Local',
      showJustification: 'Mostrar Justificativa:',
      labelYear: 'Ano',
      previewImageNewTab:
        'Para visualizar em outra aba, clique na imagem acima',
      previewVideoNewTab:
        'Para visualizar em outra aba, clique duas vezes em cima do vídeo acima',
      previewAudioNewTab:
        'Para visualizar em outra aba, clique duas vezes em cima do aúdio acima',
      questionBody: 'Enunciado da questão (Obrigatório *)',
      justification: 'Justificativa das alternativas',
      questionBodyPreview: 'Enunciado da questão:',
      questionAnswer: 'Alternativas',
      labelText: 'Texto',
      labelImage: 'Imagem',
      labelImageContent: 'Adicionar .jpg, .png e .gif',
      labelVideo: 'Video',
      labelVideoContent: 'Adicionar vídeo',
      labelAudio: 'Áudio',
      labelDissertation: 'Dissertativa',
      labelAudioPodcast: 'Áudio Podcast',
      labelAudioContent: 'Adicionar áudio',
      labelQuestionType: 'Tipo de resposta',
      labelQuestionMultipleAnswer: 'Múltipla escolha',
      labelQuesTionEssay: 'Questões dissertativas ',
      labelQuestionOther: 'Outros',
      labelMainCategory: 'Categoria principal',
      labelSecondaryCategory: 'Categoria secundária',
      labelQuestionDifficulty: 'Qual a dificuldade da questão?',
      labelQuestionEasy: 'Fácil',
      labelQuestionMedium: 'Média',
      labelQuestionHard: 'Difícil',
      labelPreviewQuestion: 'Prévia',
      labelModalPreview: 'Prévia de visualização da questão',
      exportPdf: {
        watherMark: 'CCD Daily',
        alternativesText: 'Alternativas',
        justifications: {
          title: 'Justificativa',
          videoLink: 'VIDEO',
          audioLink: 'AUDIO',
        },
        alternatives: {
          title: 'Justificativa',
          videoLink: 'VIDEO',
          audioLink: 'AUDIO',
        },
        correctAnswer: '[Correta]',
        incluse: {
          answers: 'Incluir Respostas',
          justifications: 'Incluir Justificativas'
        },
        fileName: 'simulado'
      },
      labelInsertAlternative:
        'Insira as alternativas (*máximo 5 alternativas*)',
      labelEditAlternative: 'Alternativas:',
      labelEditDissertative: 'Formas de resposta :',
      labelCorrectAlternativa:
        'Assinale, abaixo da alternativa, qual é a resposta Correta',
      correctAlternative: 'RESPOSTA CORRETA',
      placeholderTextInput: 'Escreva aqui a resposta em formato de texto',
      labelAddAlternative: '+ NOVA ALTERNATIVA',
      labelRemoverAlternative: '- REMOVER ALTERNATIVA',
      atLeastOneCorrect:
        'Toda questão deve ter pelo menos uma alternativa correta e não podem ter alternativas em branco!',
      applyCouser: 'Aplicar grupos',
      createWithSucess: 'Questão criada com sucesso!',
      updateWithSucess: 'Questão alterada com sucesso!',
      activeMediaAnswer: 'Ativar resposta em mídias:',
      essayAnswer: 'Escolha a forma de resposta para o usuário responder',
      maxPhoto: 'Máximo',
      minPhoto: 'Mínimo',
      justification: 'Justificativa do enunciado:',
      mainCategorySubtitle: '(somente uma opção)',
      secondaryCategorySubtitle: '(uma ou mais opções)',
      messagesVerify: {
        withoutLocation: 'Preencha o campo de local corretamente.',
        withoutYear: 'Insira um ano para a questão.',
        withoutDescription: 'Preencha o campo de descrição corretamente.',
        withoutMainCategory: 'Insira uma categoria principal.',
        withoutOptionAnswer: 'Insira um tipo de resposta para a questão',
        withoutCourse: 'Insira um curso',
      },
    },
    newExame: {
      description:
        'Preencha os campos abaixo para adicionar uma nova prova / simulado',
      labelTile: 'Titulo da prova / simulado',
      selectTypeOfQuiz: 'Escolha o tipo de teste',
      labelImage: 'Imagem de capa da prova',
      generalOrientation: 'Orientações gerais',
      pickSomeMockOrientation: 'Selecione algumas orientações pré-definidas:',
      exameDescript: 'Descritivo da prova',
      questionsNum: 'Número de questões',
      dateTimeExame: 'Data e hora da publicação',
      spanTime: 'Duração da prova em (hrs)',
      exptionDate: 'Data de exibição',
      expirationDate: 'Data de expiração',
      noTime: 'Duração livre',
      noStart: 'Não possui hora de ínicio e termino (exibição ou expiração)',
      exameAvailable: 'Disponibilidade da prova',
      group: 'Adicionar usuários por grupo',
      individual: 'Adicionar aluno individual',
      questionCategories: 'Categoria das questões',
      questionFilter: 'Filtro de questões',
      questionType: 'Tipo de questão',
      questionAnswerType: 'Tipo de resposta',
      labelRemoveErro: 'Erro ao remover Simulado',
      year: 'Ano',
      placeholderInitialYear: 'Ex: 2000',
      placeholderFinalYear: 'Ex: 2010',
      placeholderLocation: 'Ex: FUVEST, UNICAMP',
      labelFilter: 'Filtrar',
      questionsAvailable: 'Questões Disponíveis',
      insertedQuestion: 'Inseridas',
      labelSearch: 'Pesquisar por palavra chave',
      lableInidividualModal: 'Lista de usuários',
      hasResponseAlertModal:
        'Identificamos que um usuário já respondeu ao simulado. Se avançar, as respostas adicionadas no simulado não poderão ser respondidas. Deseja avançar mesmo assim?',
      labelObservationQuestions:
        'Para ordenar as questões disponíveis conforme sua preferência, você pode selecioná-las na ordem desejada ou após essa ação, na listagem de questões inseridas, arrastar e soltar com o mouse para organizá-las',
      labelOrientation: 'Orientação:',
      tooltipEssay: 'O campo de múltipla escolha já está selecionado',
      tooltipAlternative: 'O campo de dissertação já está selecionado',
      orientations: {
        firstOrientation:
          ' Escolha um ambiente confortável e calmo para realização da sua prova',
        secondOrientation:
          'Você terá tempo fixo para a realização da sua prova, conforme consta no countdown no canto superior direito de sua tela.',
        thirdOrientation:
          'Quando começar a prova, você terá que finalizá-la. Assim, organize-se quanto às suas obrigações e bateria do seu dispositivo. Não será permitido continuar ou realizar novamente a prova.  Após abrir a prova você deverá concluir.',
        fourthOrientation:
          'Faça sprints de 30 minutos e pare, mesmo se você não estiver cansado, por 5 minutos cronometrados - levante, beba um gole d´água (ou chá com cafeína), um café (caso goste) e coma algo. Retorne, impreterivelmente, após 5 minutos de pausa.',
        fifthOrientation:
          ' Evite alimentos doces, em especial, no início da prova - irá atrapalhar sua concentração (use nos minutos finais). Mascar chicletes "zero calorias" é recomendado.',
        sixthOrientation:
          'Ao responder cada pergunta, o sistema salva as questões automaticamente - não será permitido retornar.',
      },
      pageTitles: {
        test: 'Prova',
        students: 'Inserir alunos',
      },
      addedUsers: 'Usuários já adicionados',
      addedCourses: 'Cursos já adicionados',
      inOrder: 'Questões devem ser respondidas em ordem?',
      yes: 'Sim',
      no: 'Não',
      easies: 'Fáceis',
      mediuns: 'Médias',
      hards: 'Difíceis',
      exameDuration: 'Exemplo: 01:30 = 1,5',
      openList: 'Abrir listagem',
      descriptionType: 'Tipo de enunciado',
      until: 'até',
      cleanAllQuestions: 'Remover todas',
      sucessExame: 'Cadastrado com sucesso',
      messagesVerify: {
        withoutName: 'Insira um nome para o simulado.',
        withoutDescription: 'O simulado precisa ter uma descrição.',
        withoutMainCategory: 'O simulado precisa ter uma categoria principal.',
        withoutDistributionOption:
          'O simulado precisa ser disponibilizado por usuários ou por curso.',
        withoutQuestions: 'O simulado deve ter pelo menos uma questão.',
        withoutExhibitionDate: 'O simulado precisa ter uma data de exibição.',
        withoutExpirationDate: 'O simulado precisa ter uma data de expiração.',
        withoutDuration:
          'O simulado precisa ter a duração livre ou um período determinado',
        durationBellowZero:
          'A duração do simulado precisa ser um número maior que zero.',
        durationDatesIncorrect:
          'A data de exibição deve ser anterior à data de expiração;',
      },
    },
    userMediaAnswer: {
      url: 'correcaomidias',
      correction: 'correção',
      user: 'Usuário',
      name: 'Nome',
      status: 'Status',
      waitingAnswer: 'Aguardando correção',
      description: 'Enunciado',
      userAnswer: 'Resposta enviada pelo usuário:',
      answers: 'Respostas',
      correctionTag: 'Selecione a correção para a questão:',
      correctionComment: 'Adicione um comentário para a correção',
      correctedWithSucess: 'Correções salvas com sucesso',
      correctionSavedButQuizNotFinished:
        'Correções salvas, lembre-se após finalizada a correção do simulado não será possivel editar as correções.',
      finishCorrection: 'Finalizar correção',
      textAnswer: 'Resposta escrita:',
    },
    notifications: {
      labelTypeNotification: 'Selecione o tipo de notificação',
      labelText: 'Texto',
      labelUrl: 'URL',
      labelContent: 'Conteúdo',
      labelQuiz: 'Simulados e provas',
    },
    goBackButtonText: 'Cancelar',
    submitButtonText: 'Próximo',
    sendButtonText: 'Enviar',
    scheduleButtonText: 'Agendar',
  },
  enum: {
    profileType: {
      admin: 'Administrador',
      user: 'Usuário',
      operator: 'Operador',
      marketing: 'Marketing',
      quizer: 'Quizer',
      teacher: 'Professor',
      uploader: 'Uploader',
      kanbanManager: 'Gerenciador de Kanban',
    },
    contentType: {
      daily: 'Daily',
      fixed: 'Fixo',
    },
    userStatusType: {
      ok: 'Vigente',
      due: 'A vencer',
      overdue: 'Vencido',
    },
    userActivity: {
      active: 'Ativo',
      inactive: 'Inativo',
    }
  },

  // external components
  pushNotification: {
    url: '/painel/push-notification/',
    pageTitle: 'Criar notificações',
    sidebarTitle: 'Notificações',
    messages: {
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      success_schedule: 'Notificação agendada com sucesso.',
      successCreate: 'Notificação enviada com sucesso.',
      errors: {
        noUser:
          'Nenhum dos usuários selecionados tem acesso a esse conteúdo ou simulado.',
        usersWithoutPermission:
          'Existem pessoas que não estão ativas ou matriculadas em um curso, conteúdo ou simulado. Portanto, elas não receberão esta mensagem.',
        continue: 'Deseja enviar mesmo assim?',
        invalid_users: 'Selecione ao menos um usuário ou enviar para todos',
        invalid_description: 'Descrição inválida',
        invalid_title: 'Título inválido',
        invalid_past_scheduleDate:
          'A data de envio deve ser posterior ao momento atual',
        invalid_scheduleDate: 'Selecione uma data para o agendamento',
        generic: 'Algo deu errado, verifique os campos tente novamente.',
      },
    },
    form: {
      blockTitle: 'Detalhes da notificação',
      types: {
        label: 'Desejo enviar para',
      },
      title: {
        label: 'Título da notificação',
      },
      body: {
        label: 'Descrição',
      },
      scheduleDate: {
        label: 'Data de envio',
      },
      isSchedule: {
        label: 'Agendar?',
      },
      hasImage: {
        label: 'Incluir imagem no corpo da notificação?',
      },
      sendToAll: {
        label: 'Enviar para todos',
      },
      image: {
        dragger: {
          title: 'Imagem do corpo da notificação',
          tip: 'Clique aqui ou arraste e solte uma imagem dentro da área tracejada',
        },
      },
      datatable: {
        title: 'Usuários',
      },
    },
    preview: {
      titlePlaceHolder: 'Digite um título',
      descriptionPlaceHolder: 'Digite uma descrição',
    },
    dataTable: {
      company: {
        title: 'Empresa',
        key: 'companies',
      },
    },
  },
  chat: {
    url: '/painel/chat/',
    pageTitle: 'Criar notificações',
    sidebarTitle: 'Mensagens',
    messages: {
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      success_schedule: 'Notificação agendada com sucesso.',
      successCreate: 'Notificação enviada com sucesso.',
      errors: {
        noUser:
          'Nenhum dos usuários selecionados tem acesso a esse conteúdo ou simulado.',
        usersWithoutPermission:
          'Existem pessoas que não estão ativas ou matriculadas em um curso, conteúdo ou simulado. Portanto, elas não receberão esta mensagem.',
        continue: 'Deseja enviar mesmo assim?',
        invalid_users: 'Selecione ao menos um usuário ou enviar para todos',
        invalid_description: 'Descrição inválida',
        invalid_title: 'Título inválido',
        invalid_past_scheduleDate:
          'A data de envio deve ser posterior ao momento atual',
        invalid_scheduleDate: 'Selecione uma data para o agendamento',
        generic: 'Algo deu errado, verifique os campos tente novamente.',
      },
    },
    form: {
      blockTitle: 'Detalhes da notificação',
      types: {
        label: 'Desejo enviar para',
      },
      title: {
        label: 'Título da notificação',
      },
      body: {
        label: 'Descrição',
      },
      scheduleDate: {
        label: 'Data de envio',
      },
      isSchedule: {
        label: 'Agendar?',
      },
      hasImage: {
        label: 'Incluir imagem no corpo da notificação?',
      },
      sendToAll: {
        label: 'Enviar para todos',
      },
      image: {
        dragger: {
          title: 'Imagem do corpo da notificação',
          tip: 'Clique aqui ou arraste e solte uma imagem dentro da área tracejada',
        },
      },
      datatable: {
        title: 'Usuários',
      },
    },
    preview: {
      titlePlaceHolder: 'Digite um título',
      descriptionPlaceHolder: 'Digite uma descrição',
    },
  },
  emoji: {
    search: 'Procurar',
    clear: 'Limpar', // Accessible label on "clear" button
    notfound: 'Nenhum emoji encontrado',
    skintext: 'Escolha seu tom de pele padrão',
    categories: {
      search: 'Procurar Resultados',
      recent: 'Usado frequentemente',
      smileys: 'Sorrisos e Emoções',
      people: 'Pessoas e Corpo',
      nature: 'Animais e Natureza',
      foods: 'Comida e bebida',
      activity: 'Atividade',
      places: 'Viagem e lugares',
      objects: 'Objetos',
      symbols: 'Símbolos',
      flags: 'Bandeiras',
      custom: 'Personalizado',
    },
    categorieslabel: 'Categorias de emojis',
    skintones: {
      1: 'Tom de pele padrão',
      2: 'Pele clara',
      3: 'Tom de pele médio-claro',
      4: 'Tom de pele médio',
      5: 'Pele média escura',
      6: 'Pele Escura',
    },
  },
  userPlanOption: {
    bronze: 'Bronze',
    silver: 'Prata',
    gold: 'Ouro',
    black: 'Black',
  },
};
